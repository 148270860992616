import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import * as moment from "moment";
import { Subscription, timer } from "rxjs";
import { map } from "rxjs/operators";
import { AppConfig } from "../configs/app.config";
import { IResponse } from "../interfaces/response.interface";
import { IUserLogin } from "../interfaces/user.interface";

/** การยืนยันตัวตน */
@Injectable({ providedIn: 'root' })
export class AuthService extends AppConfig {

    /** expire Timeout (sec) */
    private _timeout = 5;

    /** เก็บข้อมูล key ของ User Login */
    private _accessTokenKey = "ssid";

    /** ข้อมูล access token ไว้ยืนยันตัวตน */
    private _accessToken?: IAcccessToken;

    /** ข้อมูลผุ้ใช้งานที่เข้าสู่ระบบ */
    private _userLogin?: IUserLogin;

    constructor(
        private _http: HttpClient,
    ) {
        super();
        this._loadAccessToken();
    }

    /** ดึงข้อมูล user login */
    initalizeLoadUserLogin(responseToken: string = '') {
        return this._http
            .get<IResponse<any>>('/api/Authentication/Profile?hasToken=' + (responseToken == '1'))
            .pipe(map(res => {
                this._userLogin = res.data;
                this._initializeAccessToken();
            }));
    }

    /** ใส่ค่า access token ไว้ที่ตัวแปร _accessToken */
    private _loadAccessToken() {
        const tokenString = localStorage.getItem(this._accessTokenKey);
        if (tokenString) this._accessToken = JSON.parse(atob(tokenString)) as IAcccessToken | undefined;
        else this._accessToken = undefined;
    }

    /** ลบ token ออกจากระบบ */
    removeAccessToken() {
        this._accessToken = undefined;
        localStorage.removeItem(this._accessTokenKey);
        if (this._accessTokenSubscription) this._accessTokenSubscription.unsubscribe();
    }

    /** กำหนด token ไว้ในระบบ */
    setAccessToken(token: IAcccessToken): void {
        this._accessToken = Object.assign({}, token);
        localStorage.setItem(this._accessTokenKey, btoa(JSON.stringify(this._accessToken)));
    }

    /** ดึงข้อมูล token */
    get accessToken(): string {
        this._loadAccessToken();
        return this._accessToken?.token || '';
    }

    /** ดึงข้อมูลผู้ใช้งานที่เข้าสู่ระบบ */
    get userLogin() { return this._userLogin; }

    /** แปลงข้อมูล token และ ทำการต่ออายุ Token อัตโนมัติ */
    private _accessTokenSubscription?: Subscription;
    private _initializeAccessToken(): void {
        try {
            if (this._accessTokenSubscription) this._accessTokenSubscription.unsubscribe();
            if (!this._accessToken) return;
            const curDate = moment();
            const endDate = moment(this._accessToken?.expires);
            const difDate = endDate.diff(curDate);
            const timeout = this._timeout * 1000;
            const ms = moment.duration(difDate).asMilliseconds();
            if (ms > timeout) this._accessTokenSubscription = timer(ms - timeout).subscribe(() => {
                this.initalizeLoadUserLogin('1').subscribe();
            });
            else this.removeAccessToken();
        }
        catch (ex) { console.warn(ex); }
    }
}


export interface IAcccessToken {
    expires: string;
    token: string;
}